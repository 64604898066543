/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Link } from 'gatsby';
import { useState, type FC } from 'react';
// eslint-disable-next-line camelcase
import Website_banner from '../../images/website_banner.jpg';
import bharathlogo from '../../images/aatmanirbhar-bharat-logo.png';
import logo from '../../images/logoNew.png';
import BurgerMenu from './burger-nav';
import HeaderCallButton from './call-btn';
import LayoutNav from './navigation';
import Image from '../../components/shared/image';




const LayoutHeader: FC = () => {

  const [loaded, setLoaded] = useState(false)

  return (
    <div className='fixed top-0 left-0 right-0 z-50
      bg-white shadow-sm min-h-20 max-w-[2100px] mx-auto border-b '>
    <header
      className="flex items-center justify-between fixed top-0 left-0 right-0 z-50
      bg-white shadow-sm min-h-20 max-w-[2100px] mx-auto border-b "
    >
      <div className="px-6 mx-auto w-full ">
        <div className="flex justify-between items-center">
          <Link className="flex space-x-2 flex-nowrap items-center" to="/">
          <div
                style={{
                  position: 'relative',
                  width: '208px',
                  height: '76px',
                }}
              >
                {' '}
                {!loaded && (
                  <div
                    style={{
                      backgroundColor: 'grey',
                      width: '208px',
                      height: '76px',
                    }}
                  />
                )}{' '}
                <img
                  alt="Logo"
                  onLoad={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    setLoaded(true);
                  }}
                  src={logo}
                  style={{
                    position: loaded ? 'relative' : 'absolute',
                    visibility: loaded ? 'visible' : 'hidden',
                    width: '208px',
                    height: '76px',
                  }}
                />{' '}
              </div>
          </Link>

          <LayoutNav />
          <div />

          <BurgerMenu />
        </div>
      </div>

      <div className="xl:flex space-x-4 hidden items-center pr-6">
        <div className="min-w-14">
          <img alt="bharath" height={58} src={bharathlogo} width={48} />
        </div>
        <HeaderCallButton />
      </div>

    </header>
    {/* <div className='sm:mt-[5rem] mt-[5rem]'>
        <Image alt='banner' src={Website_banner}/>
      </div> */}
    </div>
  );
};


export default LayoutHeader;
